import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/auth";

export const sentOTP = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/users/send-otp`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("updateAuth", item);
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to login: ${error}`,
      });
      console.error(`Failed to login:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot login offline.`,
    });
  }
};

export const checkOTP = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/users/check-otp`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("updateAuth", response.data);
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to login: ${error}`,
      });
      console.error(`Failed to login:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot login offline.`,
    });
  }
};

export const syncAuth = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/users/sync`;
    const params = {};
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("updateAuth", response.data);
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to sync: ${error}`,
      });
      console.error(`Failed to sync:`, error);
    }
  } else {
    // store.commit("setError", {
    //   isError: true,
    //   error: `Cannot sync offline.`,
    // });
  }
};
